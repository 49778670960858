import React from 'react';

export default function SliderInner({
  children,
  width,
  active,
  count,
  style,
  ...other
}) {
  return (
    <div
      style={{
        ...style,
        display: `flex`,
        width: width ? `${width * count}px` : `${100 * count}%`,
        transition: `all 0.4s ease`,
        transform: width
          ? `translateX(${-width * active}px)`
          : `translateX(${-(100 / count) * active}%)`,
      }}
      {...other}
    >
      {children}
    </div>
  );
}
